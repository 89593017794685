<template>
	<div class="main">
		<!-- 我的认领 -->
		<div class="nav-lf">
			<MusicType :width="952" :type="type" :style="{ paddingLeft: num + 'px' }" :tabsList="tabsList"
				:dropdownList="dropdownList"></MusicType>
		</div>
		<div class="main-box">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
import MusicType from "@/components/content/MusicType.vue";
export default {
	props: {},
	computed: {},
	components: {
		MusicType
	},
	data() {
		return {
			num:'',
      type:false,
      dropdownList:[],
			// tabs数据
			tabsList: [{
				name: this.$t('completed'),
				url: '/personalCenter/myClaim/complete'
			},
			{
				name: this.$t('unfinished'),
				url: '/personalcenter/myclaim/unfinished'
			},
		
			],
		}
	},
	methods: {

	}
}
</script>

<style lang="less" scoped>
.main {
	// color: palevioletred;
	::v-deep .nav .el-menu-item.is-active {
			border-bottom: 2px solid @lineColor;
		}
	.nav-lf {
		margin-left: 28px;
	}
	.main-box {
		margin-left: 30px;
	}
}
</style>